const mdEditorStyles = {
  ".mdxeditor": {
    "lineHeight": "1.6",
    "fontSize": "16px",
    "color": "#333",
    "fontFamily": "'Inter', sans-serif",
    "padding": "1rem",
    // "backgroundColor": "#fafafa",
    // "borderRadius": "8px",
    // "border": "1px solid #ddd",
  },
  ".mdxeditor p": {
    "marginBottom": "1em",
  },
  // using our own diff viewer
  ".mdxeditor .mdxeditor-diff-editor": {
    display: 'none',
  },
  ".mdxeditor .mdxeditor-source-editor": {
    // display: 'none',
  },
  "div .custom-diff-source-toggle": {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
  },
  ".custom-diff-source-toggle > button": {
    border: '1px solid #ddd',
    paddingX: '0.5rem',
  },
  ".mdxeditor li": {
    "marginBottom": "0.5em",
    "marginLeft": "1.5em",
    "paddingLeft": "1em",
    "textIndent": "-1em",
  },
  ".mdxeditor ul, .mdxeditor ol": {
    "paddingLeft": "1.5em",
  },
  ".mdxeditor h1": {
    "fontSize": "2rem",
    "fontWeight": "bold",
    "marginBottom": "0.75em",
  },
  ".mdxeditor h2": {
    "fontSize": "1.75rem",
    "fontWeight": "bold",
    "marginBottom": "0.75em",
    "borderBottom": "2px solid #ddd",
    "paddingBottom": "0.3em",
  },
  ".mdxeditor h3, .mdxeditor h4, .mdxeditor h5, .mdxeditor h6": {
    "fontWeight": "700 !important",
    "marginBottom": "0.5em",
  },
  ".mdxeditor blockquote": {
    "borderLeft": "4px solid #ccc",
    "margin": "1em 0",
    "padding": "0.5em 1em",
    "color": "#555",
    "fontStyle": "italic",
    "backgroundColor": "#f9f9f9",
    "borderRadius": "4px",
  },
  ".mdxeditor pre": {
    "backgroundColor": "#282c34",
    "color": "#fff",
    "padding": "1em",
    "borderRadius": "6px",
    "overflowX": "auto",
  },
  ".mdxeditor code": {
    "fontFamily": "'Fira Code', monospace",
    "backgroundColor": "#eee",
    "padding": "0.2em 0.4em",
    "borderRadius": "4px",
    "fontSize": "0.95em",
  },
  ".mdxeditor pre code": {
    "backgroundColor": "transparent",
    "padding": "0",
  },
  ".mdxeditor hr": {
    "border": "none",
    "borderTop": "1px solid #ddd",
    "margin": "1.5em 0",
  },
  ".mdxeditor a": {
    "color": "#007bff",
    "textDecoration": "none",
  },
  ".mdxeditor a:hover": {
    "textDecoration": "underline",
  },

  // Table Styling
  ".mdxeditor table": {
    "width": "100%",
    "borderCollapse": "collapse",
    "margin": "1em 0",
    "fontSize": "1em",
    "backgroundColor": "#fff",
    "borderRadius": "6px",
    "overflow": "hidden",
    "border": "1px solid #ddd",
  },
  ".mdxeditor th, .mdxeditor td": {
    "border": "1px solid #ddd",
    "padding": "12px",
    "textAlign": "left",
  },
  ".mdxeditor th": {
    "backgroundColor": "#f5f5f5",
    "fontWeight": "bold",
  },
  ".mdxeditor tr:nth-child(even)": {
    "backgroundColor": "#f9f9f9",
  },
  ".mdxeditor tr:hover": {
    "backgroundColor": "#f1f1f1",
  },
  ".mdxeditor-toolbar": {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem",
    // "top": "0.5rem",
    "padding": "0.5rem",
    "backgroundColor": "#f5f5f5",
  },
  ".cm-gutters": {
    display: "none !important",
  },
  // ".mdxeditor-toolbar button": {
  //   "background": "none",
  //   "border": "none",
  //   "padding": "0.4rem 0.6rem",
  //   "borderRadius": "4px",
  //   "cursor": "pointer",
  //   "fontSize": "1rem",
  //   "color": "#333",
  //   "transition": "background-color 0.2s ease, color 0.2s ease",
  // },
  ".mdxeditor-toolbar button:hover": {
    "backgroundColor": "#e0e0e0",
  },
  ".mdxeditor-toolbar button:active": {
    "backgroundColor": "#d0d0d0",
  },
  ".mdxeditor-toolbar button:disabled": {
    "opacity": "0.5",
    "cursor": "not-allowed",
  },
  ".mdxeditor-toolbar .active": {
    "backgroundColor": "#007bff",
    "color": "#fff",
  },
  ".mdxeditor-toolbar .separator": {
    "width": "1px",
    "height": "24px",
    "backgroundColor": "#ddd",
    "margin": "0 0.5rem",
  },
};

export const styles = {
  global: {
    ...mdEditorStyles,
    "body": {
      color: '#10122A',
      fontSize: {
        base: '16px',
        lg: "1.4rem"
      },
    },
    "h1, h2": {
      "lineHeight": "0.9 !important",
    },
    "h3, h4, h5, h6": {
      "lineHeight": "1 !important",
      "fontWeight": "500 !important",
    },
    ":root": {
      // "font-family": "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
      "lineHeight": "1.5",
      "fontHeight": "400",
      "--accent": "black",
      "--canvas": "rgba(245, 245, 245, 0.99)", // Approximation of `color-mix`
      "--border": "rgba(200, 200, 200, 0.9)", // Approximation of `color-mix`
      "--text": "rgba(50, 50, 50, 0.75)", // Approximation of `color-mix`
      "--border-radius": "24px",
      "--border-width": "3px",
      "--trail-size": "25px",
    },
    "html, body, #root": {
      height: "100%",
      margin: "0"
    },
    'input[type="file"]': {
      display: 'none'
    },
    ".dndflow": {
      "flexDirection": "column",
      display: "flex",
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow aside": {
      "borderRight": "1px solid #eee",
      padding: "15px 10px",
      // "fontSize": "8px",
      background: "#fcfcfc"
    },
    "a": {
      'textDecoration': "none !important"
    },
    ".is-active": {
      background: "#f9f9f9"
    },
    ".dndflow aside .description": {
      "marginBottom": "10px"
    },
    ".dndflow .reactflow-wrapper": {
      "flexGrow": "1",
      height: "100%"
    },
    ".dndflow .selectall": {
      "marginTop": "10px"
    },
    "@media screen and (min-width: 768px)": {
      ".dndflow": {
        "flexDirection": "row"
      },
      ".dndflow aside": {
        width: "20%",
        "maxWidth": "250px"
      }
    },
    ".default-box": {
      border: "1px solid black",
      "boxShadow": "0 0 5px rgba(0, 0, 0, 0.2)"
    },
    ".run-connected": {
      color: "white",
      "fontWeight": "900"
    },
    ".run-connected::before": {
      content: '""',
      position: "absolute",
      top: "-2px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      "borderRadius": "inherit",
      background: "linear-gradient(45deg, #ff7f7f, #d1ffbd, #d1ffbd)",
      "backgroundSize": "400%",
      animation: "glow 3s infinite linear",
      "zIndex": "-1"
    },
    ".edge-hovered": {
      color: "#ff7f7f",
      "backgroundColor": "#ff7f7f",
      "strokeWidth": "4px"
    },
    ".rjsf": {
      width: "100%"
    },
    ".react-flow__node-annotation": {
      "font-size": '16px',
      width: "200px",
      color: "#2e2d2b",
      "font-family": "monospace",
      position: "absolute",
    },
    ".react-flow__node-annotation .arrow": {
      position: "absolute",
      "font-size": "24px"
    },
    ".animate-on-hover": {
      transition: "all 0.2s",
      "will-change": "transform",
      "transform": "translateY(0)"
    },
    ".animate-on-hover:hover": {
      "transform": "translateY(-1px)"
    },
    ".lp-card": {
      // "boxShadow": "4px 8px 8px #DCDEF6",
      // full black
      // "boxShadow": "10px 10px 0 8px #000",
      "shadow": { base: 0, md: 'md' },
      "borderWidth": { base: 0, md: '1px' },
      "borderRadius": { base: 0, md: "10px", },
      "borderColor": "gray.300"
    },
    ".lp-bg-img": {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundSize: "cover",
      backgroundPosition: "top",
      backgroundRepeat: "no-repeat",
      // rounded: { base: 0, md: '10' },
      zIndex: "-1",
    },
    ".animated-border": {
      position: "relative",
      background: "white",
    },
    ".animated-border::before": {
      content: '""',
      position: "absolute",
      top: "-8px",
      left: "-8px",
      right: "-8px",
      bottom: "-8px",
      borderRadius: "inherit",
      background: "linear-gradient(90deg, #F7B1D2, #727ADB, #F7B1D2)",
      backgroundSize: "200% 200%",
      animation: "moveGradient 3s linear infinite, glowPulse 3s linear infinite",
      zIndex: "-1"
    },
    ".landing-vstack": {
      width: '100%',
      justifyContent: 'center',
      color: 'white',
      alignItems: { base: 'center', lg: 'stretch' },
      spacing: 8,
      px: { base: 4, lg: 40 },
      py: { base: 12, lg: 40 }
    },
    "@keyframes moveGradient": {
      "0%": {
        backgroundPosition: "0% 50%",
      },
      "50%": {
        backgroundPosition: "100% 50%",
      },
      "100%": {
        backgroundPosition: "0% 50%",
      }

    },
    "@keyframes glowPulse": {
      "0%, 100%": {
        boxShadow: "0 0 5px #727ADB80",
      },
      "50%": {
        boxShadow: "0 0 50px #727ADB",
      }
    }
  }
};
