import * as React from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import customTheme from "src/styles/theme";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { usePageContext } from "vike-react/usePageContext";
import MarkdownEditor from "src/components/md-editor";

const AmplitudeManager = () => {
  React.useEffect(() => {
    if (import.meta.env.VITE_AMPLITUDE_ENABLED) {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
      amplitude.init("fd34d20333661b857aee70f53750a1d0", {
        serverZone: "US",
        autocapture: { elementInteractions: true },
      })
    }

  }, []);

  return null;
}

const embedTheme = extendTheme({
  styles: {
    global: () => ({
      body: {
        bg: "",
      },
    }),
  },
  components: {
    Input: {
      baseStyle: {
        backgroundColor: "white", // Apply white background to all Input components
      },
      variants: {
        outline: {
          field: {
            backgroundColor: "white", // Ensures white background even in outline variant
          },
        },
        filled: {
          field: {
            backgroundColor: "white", // Ensures white background even in filled variant
          },
        },
      },
    },
  },
}, customTheme);

const LayoutDefault = ({ children }: { children: React.ReactNode }) => {
  const { urlParsed } = usePageContext();

  if (urlParsed.pathname.startsWith("/embed")) {
    return <ChakraProvider theme={embedTheme} >
      {children}
    </ChakraProvider>
  }

  return (
    <>
      {/* <OlarkManager /> */}
      {/* <AmplitudeManager /> */}
      <ChakraProvider
        resetCSS
        theme={customTheme}
        toastOptions={{
          defaultOptions: {
            position: "top-right",
            variant: "solid",
            isClosable: true,
          },
        }}
      >
        {children}
      </ChakraProvider >
    </>
  )
}

export default LayoutDefault;
